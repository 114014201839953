
<template>
    <div class="video">
        <div class="warpper">
            <div class="video-icon mb-40">
                <div class="video-icon mb-40">
                    <a class="popup-video btn-icon" href="https://www.youtube.com/watch?v=SqBSkGY7dsA"
                        data-animation="bounceIn" data-delay=".4s"><i class="fas fa-play"></i></a>
                </div>
            </div>
        </div>
    </div>
</template>

  
<script>
import $ from 'jquery'
import 'magnific-popup'

export default {
    data() {
        return {
        };
    },
    mounted() {
        var popUp = $('.popup-video');
        console.log(popUp)
        if (popUp.length) {
            popUp.magnificPopup({
                type: 'iframe'
            });
        }
    }
};
</script>



  
<style scoped lang="less">
.video {
    width: 100%;
    height: 526px;
    background: url("~@/assets/images/section_bg01.jpg") no-repeat;
    background-size: cover;
    background-position: center;

    .warpper {
        height: 100%;
        position: relative;

        .video-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        // padding: 220px 0;
        .btn,
        .video-icon a {
            background: #ff4495;
            background: -moz-linear-gradient(top, #ff4495 0%, #ff6d6d 100%);
            background: -webkit-linear-gradient(top, #ff4495 0%, #ff6d6d 100%);
            background: linear-gradient(to bottom, #ff4495 0%, #ff6d6d 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff4495', endColorstr='#ff6d6d', GradientType=0)
        }

        .btn::before {
            background: #ff6d6d;
            background: -moz-linear-gradient(top, #ff6d6d 0%, #ff4495 100%);
            background: -webkit-linear-gradient(top, #ff6d6d 0%, #ff4495 100%);
            background: linear-gradient(to bottom, #ff6d6d 0%, #ff4495 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff6d6d', endColorstr='#ff4495', GradientType=0)
        }

        .video-icon a.btn-icon {
            position: relative
        }

        .video-icon a.btn-icon::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: -2px;
            left: -2px;
            bottom: -2px;
            right: -2px;
            border-radius: inherit;
            border: 1px solid #fff;
            -webkit-animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite;
            animation: btnIconRipple 2s cubic-bezier(0.23, 1, 0.32, 1) both infinite
        }

        .video-icon a {
            color: #FF6162;
            width: 80px;
            height: 80px;
            display: inline-block;
            text-align: center;
            line-height: 80px;
            border-radius: 50%
        }

        .video-icon a i {
            color: #fff;
            font-size: 30px;
            line-height: 1;
            position: relative;
            right: -2px;
            top: 5px
        }

        @-webkit-keyframes btnIconRipple {
            0% {
                border-width: 4px;
                -webkit-transform: scale(1);
                transform: scale(1)
            }

            50% {
                border-width: 1px;
                -webkit-transform: scale(1.5);
                transform: scale(1.5)
            }

            80% {
                border-width: 1px;
                -webkit-transform: scale(1.9);
                transform: scale(1.9)
            }

            100% {
                opacity: 0
            }
        }

        .video-icon a.btn-icon:hover {
            color: #fff
        }

    }
}

@media only screen and (max-width: 690px) {
    .video {
        // height: 2120px;
        width: 100%;
        height: 122px;

        .warpper {
            padding: 40px 0;

            .video-icon a {
                color: #FF6162;
                width: 40px;
                height: 40px;
                display: inline-block;
                text-align: center;
                line-height: 40px;
                border-radius: 50%
            }

            .video-icon a i {
                color: #fff;
                font-size: 20px;
                line-height: 1;
                position: relative;
                right: -2px;
                top: 2px
            }


        }
    }
}

@media only screen and (max-width: 1050px) and (min-width: 690px) {
    .video {
        // height: 2120px;
        width: 100%;
        height: 122px;

        .warpper {
            padding: 40px 0;

            .video-icon a {
                color: #FF6162;
                width: 40px;
                height: 40px;
                display: inline-block;
                text-align: center;
                line-height: 40px;
                border-radius: 50%
            }

            .video-icon a i {
                color: #fff;
                font-size: 20px;
                line-height: 1;
                position: relative;
                right: -2px;
                top: 2px
            }

        }
    }
}
</style>
  