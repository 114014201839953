<template>
  <div class="home">
    <Header />
    <Form />
    <Pay />
    <Video />
    <Row />
    <Banner />
    <Fast />
    <Recommend />
    <FAQ />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import Form from "@/components/form/Form";
import Row from "@/components/row_bg/RowBg";
import Recommend from "@/components/recommend/Recommend";
import Video from "@/components/video/Video";
import Banner from "@/components/banner/Banner";
import FAQ from "@/components/faq/Faq";
import Fast from "@/components/fast/Fast";
import Pay from "@/components/pay/Pay";
import Footer from "@/components/footer/Footer";
export default {
  components: {
    Header,
    Form,
    Row,
    Recommend,
    Banner,
    FAQ,
    Fast,
    Footer,
    Pay,
    Video
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.home {
  width: 100%;
  position: relative;
}
</style>
